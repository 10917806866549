<template>
	<div class="opacity-page">
		<div class="header-filter">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" placeholder="Cauta dupa nume, token" v-model="search_filter"/>
					</div>
					<div class="actions-page mobile">
						<button class="btn-tbf center blue" id="buttonSync" @click="showModal('create_discount_token', { type: 'create' })">
							<div class="loader"></div>
							<div class="text">{{ $t('general.add') }}</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>

		<template v-if="loadedList">
			<div class="total-entries row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="count-entries">{{ $t('admin_users.filters.total_entries') }}<span class="number">{{ filteredDiscountTikets.length }}</span></div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="data-list list-discount-tokens" v-if="filteredDiscountTikets.length">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-master_inst_id">
							<div class="text">ID</div>
						</div>
						<div class="column-filter column-name">
							<div class="text">Nume</div>
						</div>
						<div class="column-filter column-token">
							<div class="text">Token</div>
						</div>
						<div class="column-filter column-free">
							<div class="text">Free</div>
						</div>
						<div class="column-filter column-tag">
							<div class="text">TBF Tag</div>
						</div>
						<div class="column-filter column-users">
							<div class="text">Utilizatori</div>
						</div>
						<div class="column-filter column-updated_at">
							<div class="text">Ult. actualizare</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="discount in filteredDiscountTikets">
					<div class="row-space-tbf row-list-item" :key="discount.id">
						<div class="space-left"><icon-people v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-simple-text column-master_inst_id">
								<div class="text">{{ discount.id }}</div>
							</div>
							<div class="column-name-title column-name link" @click="showModal('create_discount_token', { type: 'edit', item: discount })">
								<div class="status" :class="[discount.is_active ? 'green' : 'red']"></div>
								<div class="name">{{ discount.name }}</div>
							</div>
							<div class="column-link column-token">
								<a :href="`https://central.tbf.ro/price?reducere=${discount.discount_token}`" target="_blank">{{ `https://central.tbf.ro/price?reducere=${discount.discount_token}` }}</a>
							</div>
							<div class="column-simple-text column-free">
								<div class="text">{{ discount.is_free ? "Gratuit" : "Cu reducere" }}</div>
							</div>
							<div class="column-simple-text column-tag">
								<div class="text">{{ discount.tbf_tag }}</div>
							</div>
							<div class="column-simple-text column-users">
								<div class="text">{{ discount.count_accounts }}</div>
							</div>
							<div class="column-simple-text column-updated_at">
								<div class="text">{{ discount.updated_at | moment('DD MMM YYYY') }}</div>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<div class="title">{{ $t('admin_users.empty_title')}}</div>
					<div class="description" v-html="$t('admin_users.empty_users_text')"></div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>
	</div>
</template>

<script>
    import IconSearch from '../../Icons/Search'
    import IconPeople from '../../Icons/People'

    export default {
    	components: {
            IconSearch,
            IconPeople
        },
        data() {
            return {
				loadedList: true,
            	search_filter: '',
            	discountTokens: [],
            }
        },
        computed: {
        	filteredDiscountTikets(){
				return getByKeywordFilter(this.discountTokens, this.search_filter)
        	}
        },
        async mounted(){
			setTimeout(() => {
				this.$root.$emit("navbar_title", 'Discount tokens');
			}, 0)
			await this.getDiscountTokens();

			this.$root.$on('refreshDiscountTokensIndex', () => {
				this.getDiscountTokens();
			})
        },
        methods: {
        	async getDiscountTokens(){
        		await axios.get('/discount-links')
        		.then(({data}) => {
        			this.discountTokens = data.data
        		})
        		.finally(() => {
	        		setTimeout(() => {
	        			this.loaded = true
	        			setTimeout(() => {
	        				$('.opacity-page').addClass('show')
	        			}, 0)
	        		}, 0)
        		})
        	},
			showModal(type, data = false){
				this.$root.$emit('open_modal', type, data);
			}
        }
    }

	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || item.discount_token.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
    .total-entries{
    	padding: 15px 0;
    	.count-entries{
    		font-size: 14px;
    		.number{
    			font-weight: 500;
    		}
    	}
    }

    .mt-second-filter{
    	margin-top: 15px;
    }

    .list-discount-tokens{
    	.row-list-item{
    		&:hover{
    			.content{
    				.column-name{
    					.name{
    						font-weight: 700;
    					}
    				}
    			}
    		}
    	}
    	.column-name{
    		flex: 0 0 400px;
    		display: flex;
    		align-items: center;
    		.status{
    			height: 10px;
    			width: 10px;
    			border-radius: 50%;
    			margin-right: 10px;
    			&.green {
    				border: 1px solid #03AE58;
    				background-color: rgba(11, 203, 106, 0.297449);;
    			}
    			&.red {
    				border: 1px solid #eb1b68;
    				background-color: rgba(235,27,104,.2);
    			}	
    		}
    		&.link{
    			cursor: pointer;
    		}
    	}
    	.column-master_inst_id{
    		flex: 0 0 60px;
    	}
    	.column-token{
    		flex: 1 1 auto;
    	}
    	.column-users{
    		flex: 0 0 160px;
    	}
    	.column-updated_at{
    		flex: 0 0 100px;
    	}
    	.column-tag{
    		flex: 0 0 200px;
    	}
		.column-free{
			flex: 0 0 100px;
		}
    }
</style>